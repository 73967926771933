// Colors
$yellow:              #ffff00;
$orange:              #ff6600;
$red:                 #ff0000;
$green:               #32cd32;
$light-green:         #aed581;
$blue:                #1d90ff;
$grey:                #e9ecef;
$purple:              purple;

$enable-shadows: true;
$prefix: "mo-";
$primary-color:       #f6c2d9;
$secondary-color:     #78ffff;
$third-color:         #ffd1f8;
$fourth-color:        #bbede1;
$text-color:          #595860;

$border-color:        #dcdcdc;
$background-color:    #fafbfc;



// Bootstrap overrides 
$primary: #f15a29;
$secondary: #375e97;
$carousel-control-color: black;

// Fonts
$serif:               "Lora", "Playfair Display", Georgia, serif;
$sans-serif:          "Montserrat", sans-serif;
$sans-serif:          "Montserrat", sans-serif;

$monospace:           "Inconsolata", monospace;

$primary-font:        $sans-serif;
$secondary-font:      $serif;

// Spacing
$mobile-space:        10px;
$desktop-space:       35px;

$shadow:          0 6px 10px 1px #0000004f;
$lg-shadow:       0 6px 10px 1px #00000085;

// Bootstrap 

$table-cell-padding-y: 1rem;

