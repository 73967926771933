.privacy-cookies {
  position: fixed;
  text-align: center;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(#fff, $primary-color);
  color: black;
  padding: 1em;
  width: 100%;

  .privacy-cookies__close {
    font-size: 1.3em;
    cursor: pointer;
    border: 4px solid white;
	background: $secondary-color;
    border-radius: 5px 10px;
	padding: 5px 10px;
	color:white;
  }

  .cookies-read-more {
    background-color: $secondary-color;
    color: white;
  }
}
