// Helpers

html {
	background: $background-color;
}

.hidden {
	display: none !important;
}

.disabled {
	pointer-events: none;
	background: $grey !important;
}

.btn {
	&:hover {
		filter: brightness(0.9);
	}
}

.hover-darken {
	&:hover {
		filter: brightness(0.8);
	}
}

.h-0 {
	height: 0 !important;
}

.border-dashed {
	border: 1px dashed;
}

.cursor-pointer {
	cursor: pointer !important;
}

.hover-primary:hover {
	color: $primary !important;
}

.focus-bigger-textarea:focus {
	height: 150px;
	width: 500px;
	max-width: 100%;
}

.btn:disabled {
	opacity: 1 !important;
}

.btn-grey{
	color: #fff;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}

.slim-select {
    .placeholder {
        background-color: transparent;
        opacity: 1;
        color: black;
		cursor: pointer;
    }
	
}

.ss-main{
	display: block;
    width: 100%;
    padding: 0.3rem 0.7rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
}
.ss-main .ss-single-selected{
	border: none;
}

.cursor-text{
	cursor: text !important;
}

.horizontal-scrollable > .row {
	overflow-x: auto;
	white-space: nowrap;
}