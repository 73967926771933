/* slide right animation  */

.jump-column {
	transition: transform 0.3s, margin 0.3s;
	cursor: pointer;
	/* for this theme */
	background: white;
}

.jump-column:hover {
	transform: scale(1.1) translateY(-100px);
}

.to-left {
	animation-name: toLeft;
	animation-duration: 3s;
	animation-iteration-count: 1;
	position: relative;
}

@keyframes toLeft {
	0% {
		-webkit-transform: translateX(100px);
		transform: translateX(100px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

/* end of slide right animation  */

/* slide left animation  */
.to-right {
	animation-name: toRight;
	animation-duration: 3s;
	animation-iteration-count: 1;
	position: relative;
	overflow: hidden;
}

@keyframes toRight {
	0% {
		-webkit-transform: translateX(-100px);
		transform: translateX(-100px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
/* end of slide right animation  */

.up-animation {
	animation-name: up;
	animation-duration: 1s;
	animation-iteration-count: 1;
	position: relative;
}

@keyframes up {
	0% {
		opacity: 0;
		transform: translate(0px, -100px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
}

.down-animation {
	animation-name: down;
	animation-duration: 1s;
	animation-iteration-count: 1;
	position: relative;
}

@keyframes down {
	0% {
		opacity: 0;
		transform: translate(0px, 50px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
}

.fadeout {
	animation: fadeOutAnimation 2s forwards;
}

.fadein {
	animation: fadeInAnimation 2s forwards;
}


@keyframes fadeOutAnimation {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
